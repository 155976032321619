function checkPath(location) {
  let flag = false;
  const paths = [
    "/payment/",
    "/pos",
    "/new-menu",
    "/catalogue",
    "/events",
    "/checkout",
    "/spf",
    "/property-maintenance",
  ];
  flag = paths.some((url) => location.includes(url));

  if (location.includes("transaction") || location.includes("table-header")) {
    flag = false;
  }

  return flag;
}

function isActivePublicMicrofrontend(location) {
  return (
    (location.pathname.includes("/checkout") &&
      !location.pathname.includes("/catalogue")) ||
    location.pathname.includes("/pos/attempt-detail") ||
    location.pathname.includes("/payment/") ||
    location.pathname.includes("/property-maintenance")
  );
}

function checkSentryErrors(event, hint) {
  const { response } = hint.originalException;
  if (
    response &&
    response.status &&
    response.status >= 400 &&
    response.status < 500
  ) {
    return null;
  }
  return event;
}

function renderMountErrorMessage() {
  document.body.style.display = "flex";
  document.body.style.justifyContent = "center";
  document.body.style.alignItems = "center";
  document.body.style.height = "100vh";
  document.body.style.margin = "0";

  const div = document.createElement("div");
  div.textContent =
    "Please check your internet connection and refresh the page";
  div.style.fontSize = "24px";
  div.style.fontFamily = "Arial, sans-serif";
  document.body.appendChild(div);
}

export {
  checkPath,
  checkSentryErrors,
  renderMountErrorMessage,
  isActivePublicMicrofrontend,
};
